import moment from "moment";
import { TimeData } from "./times";
import { EmployeeModelFragment } from "@/graphql/types";
import { i18n } from "@/plugins/i18n";

export const tomorrowPoiting = (createdAt: string, times: TimeData[]) => {
  const tomorrow = moment(createdAt).add(1, "day");
  const tomorrowData = times.find(({ exit }) => {
    return exit !== null && moment(exit).isSame(tomorrow, "day");
  })?.exit;
  return tomorrowData ? moment(tomorrowData).format("YYYY-MM-DD") : null;
};

export const getFullName = ({
  firstName,
  lastName,
}: Partial<EmployeeModelFragment>) => {
  return i18n.global.t("hr_full_name", {
    firstName,
    lastName,
  });
};

export const getFullNames = (employees: Partial<EmployeeModelFragment>[]) => {
  return employees.map((em) => getFullName(em)).join(", ");
};
