
import { defineComponent, reactive, ref } from "vue";
import JsBarcode from "jsbarcode";
import { printBarcodes } from "@/components/product/barcode-printer";

type Code = {
  value: string;
  image: string;
  title: string;
};
export default defineComponent({
  name: "BarcodePrinter",
  setup() {
    const codes = ref<Code[]>([]);
    const params = reactive({
      label: "",
      quantity: 1,
      height: 120,
      width: 180,
      gap: 10,
      columns: 4,
      titleSize: 15,
      title: "",
    });
    const edit = reactive({
      index: 0,
      label: "",
      title: "",
    });
    const opEdit = ref<any>(null);

    function getCode(label: string) {
      let canvas = document.createElement("canvas");
      label = label.replaceAll(" ", "");
      if (!label) return "";
      JsBarcode(canvas, label, {
        fontSize: 15,
      });
      return canvas.toDataURL("image/png");
    }

    function validate() {
      //const barcodeMargin = 20;
      for (let i = 0; i < params.quantity; i++) {
        const image = getCode(params.label);
        if (image) {
          codes.value.push({
            value: params.label,
            title: params.title.trim(),
            image,
          });
        }
      }
    }

    function random() {
      const min = 1000000000;
      const max = 9999999999;
      return "000" + (Math.floor(Math.random() * (max - min + 1)) + min);
    }

    function autoGenerateCode() {
      params.label = random();
    }

    function removeCode(index: number) {
      codes.value.splice(index, 1);
    }

    function openEdition(event: any, index: number) {
      edit.label = codes.value[index].value;
      edit.title = codes.value[index].title;
      edit.index = index;
      opEdit.value?.toggle(event);
    }
    function autoGenerateCodeEdit() {
      edit.label = random();
    }

    function saveEdition() {
      const image = getCode(edit.label);
      if (image) {
        codes.value[edit.index] = {
          value: edit.label,
          title: edit.title,
          image,
        };
        opEdit.value.hide();
      }
    }

    return {
      codes,
      params,
      validate,
      printBarcodes,
      autoGenerateCode,
      removeCode,
      opEdit,
      openEdition,
      edit,
      saveEdition,
      autoGenerateCodeEdit,
    };
  },
});
