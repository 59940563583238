export default {
  methods: ["Espèce", "Chèque", "Virement", "Offert", "Crédit", "Autre"],
  discountPercent: "Remise en pourcentage",
  discountAmount: "Montant de la remise",
  received: "Tendu",
  discount: "Remise",
  amount: "Montant",
  change: "Monnaie",
  count: "Paiement : {time} fois",
  remain: "Restant",
  to_pay: "Reste à payer",
  list: "Liste de payments du ticket n°{number} ",
  total: "Total",
  sold: "Solde",
  quantity: "Qnt.",
  ttcTotal: "Total TTC",
  title: "Paiment du ticket {number}",
  titleRemain: "Paiment de crédit du ticket {number}",
  customer: "Client",
  clientUndefined: "Au comptoir",
  success: "Paiement effectué",
  failed: "Paiement echoué",
  payed: "Payé",
  method: "Méthode",
  note: "Note",
  printer: ["Imprimante activée", "Imprimante désactivée"],
  given: "Tendu",
  thanks: "Merci de votre visite ! A bientôt.",
  by: "Et espère vous revoir bientôt",
  ticketNumber: "Ticket N°{number}",
  forbidden:
    "Un ticket à crédit ou paiement partiel doit être attribué à un client connu.",
  transaction: {
    title: "Paiement d'abonnment",
    success: "Paiement d'abonnement avec succès",
    refused: "Le paiement a été refusé. Réessayez plus tard.",
    failed: "Une erreur de paiement est survenue.Réessayez plus tard.",
    number: "Numéro transaction",
  },
  previous: "Règlements",
};
