import { useRemovePointingMutation } from "@/graphql/types";
import { apolloClient } from "@/plugins/apollo-client";
import { PrimeIcons } from "primevue/api";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";

type Props = {
  props: Readonly<{
    pointingId: string;
  }>;
  emit: (event: "done", ...args: any[]) => void;
};

export const useRemovePointing = ({ props, emit }: Props) => {
  const { t } = useI18n();
  const confirm = useConfirm();
  const toast = useToast();
  const { onDone, onError, mutate, loading } = useRemovePointingMutation();

  onDone(({ data }) => {
    if (data?.removePointing) {
      apolloClient.refetchQueries({
        include: ["PointingByMonth"],
      });
      toast.add({
        severity: "success",
        summary: t("hr_pointing"),
        detail: t("hr_pointing_removed_successfully"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
      emit("done");
    } else {
      toast.add({
        severity: "error",
        summary: t("hr_pointing"),
        detail: t("common_error"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });

  onError(() => {
    toast.add({
      severity: "error",
      summary: t("hr_pointing"),
      detail: t("common_error"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });

  const removePointing = (event: any) => {
    event.preventDefault();
    confirm.require({
      message: t("hr_pointing_removed_warning"),
      accept: () => void mutate({ id: props.pointingId }),
      icon: PrimeIcons.INFO_CIRCLE,
      rejectClass: "p-button-secondary p-button-outlined p-button-sm",
      acceptClass: "p-button-sm p-button-danger",
      acceptLabel: t("prime.accept"),
      rejectLabel: t("prime.reject"),
      target: event.currentTarget,
    });
  };

  return { removePointing, loading };
};
