import { useEmployeesQuery } from "@/graphql/types";
import { activeActivity } from "@/plugins/i18n";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { computed, ref } from "vue";

const FILTER = {
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  lastName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  firstName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  "function.id": {
    operator: FilterOperator.AND,
    constraints: [{ value: [], matchMode: FilterMatchMode.IN }],
  },
  "sector.id": {
    operator: FilterOperator.AND,
    constraints: [{ value: [], matchMode: FilterMatchMode.IN }],
  },
  entryDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
  departureDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
  contacts: { value: null, matchMode: FilterMatchMode.CONTAINS },
};

export const useEmployees = () => {
  const filter = ref({ ...FILTER });
  const { loading, result } = useEmployeesQuery(() => ({
    activityId: activeActivity.value.id,
  }));
  const employees = computed(() =>
    (result.value?.employees || []).map(
      ({ entryDate, departureDate, ...emp }) => ({
        ...emp,
        entryDate: entryDate ? new Date(entryDate) : null,
        departureDate: departureDate ? new Date(departureDate) : null,
      })
    )
  );

  const clearFilter = () => {
    filter.value = { ...FILTER };
  };

  return { loading, employees, filter, clearFilter };
};
