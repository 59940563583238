import {
  EmployeeModelFragment,
  useCreatePoitingMutation,
} from "@/graphql/types";
import { tomorrowPoiting } from "@/utils/poiting";
import { TimeData } from "@/utils/times";
import moment from "moment";
import { useToast } from "primevue/usetoast";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useValidation } from "vue3-form-validation";

export type CreatePointingType = "single" | "all";

type CreatePointingProps = {
  props: {
    visible: boolean;
    employees: EmployeeModelFragment[];
    createdAt: string;
    type?: CreatePointingType;
  };
  emit: (event: "update:visible" | "on-created", ...args: any[]) => void;
};
export const useCreatePointing = ({ props, emit }: CreatePointingProps) => {
  const pointingFormRef = ref<any | undefined>(undefined);
  const { t } = useI18n();
  const toast = useToast();
  const minDate = computed(() =>
    props.type == "all"
      ? moment().startOf("month").format("YYYY-MM-DD")
      : undefined
  );
  const { onDone, onError, mutate } = useCreatePoitingMutation({
    update(cache, { data }) {
      const pt = data?.createPointing;
      if (pt) {
        cache.modify({
          fields: {
            pointingByMonth(existingPointers = [], { toReference }) {
              return [
                ...existingPointers,
                ...pt.reduce((cum, cur) => {
                  if (cur.previous) {
                    return [
                      toReference(cur),
                      toReference(cur.previous),
                      ...cum,
                    ];
                  }
                  return [toReference(cur), ...cum];
                }, []),
              ];
            },
          },
        });
      }
    },
  });
  const { validateFields, errors, form } = useValidation({
    times: {
      $value: [
        {
          entry: moment(props.createdAt).startOf("day").toDate(),
          exit: null,
        },
      ],
      $rules: [(m: Date[]) => !m?.length && ""],
    },
  });

  onDone(({ data, errors }) => {
    if (data?.createPointing) {
      toast.add({
        severity: "success",
        summary: t("hr_pointing"),
        detail: t("hr_pointing_added_successfully"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
      emit("update:visible", false);
      emit("on-created");
    } else {
      const isPointed = errors[0]?.message?.includes("unique_pointing");
      if (isPointed) {
        toast.add({
          severity: "error",
          summary: t("hr_pointing"),
          detail: t("hr_already_pointed"),
          life: parseInt(process.env.VUE_APP_TOAST_LIFE),
        });
      } else {
        toast.add({
          severity: "error",
          summary: t("hr_pointing"),
          detail: t("unknownError"),
          life: parseInt(process.env.VUE_APP_TOAST_LIFE),
        });
      }
    }
  });

  onError(() => {
    toast.add({
      severity: "error",
      summary: t("unknownError"),
      detail: t("unknownError"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });

  function submit() {
    validateFields().then(({ times }) => {
      // Vérifier type de création
      const createdAt =
        props.type == "all"
          ? moment(times[0].entry as Date).format("YYYY-MM-DD")
          : props.createdAt;
      void mutate({
        input: {
          employeeIds: props.employees.map((e) => e.id),
          times: times,
          createdAt,
          tomorrowCreatedAt: tomorrowPoiting(createdAt, times as TimeData[]),
        },
      });
    });
  }

  const submitForm = () => pointingFormRef.value?.submit();

  return { errors, submit, form, pointingFormRef, submitForm, minDate };
};
