export class TimeData {
  entry: Date;
  exit?: Date;
}

export const toTimeData = (times?: string): TimeData[] => {
  if (!times) return [];
  return JSON.parse(times).map(({ entry, exit }) => ({
    entry: new Date(entry),
    exit: exit ? new Date(exit) : undefined,
  }));
};
