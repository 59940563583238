import {
  PointingByMonthDocument,
  PointingModelFragment,
  useUpdatePointingMutation,
} from "@/graphql/types";
import { apolloClient } from "@/plugins/apollo-client";
import { tomorrowPoiting } from "@/utils/poiting";
import { TimeData, toTimeData } from "@/utils/times";
import moment from "moment";
import { useToast } from "primevue/usetoast";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useValidation } from "vue3-form-validation";

type Props = {
  props: {
    visible: boolean;
    pointing: PointingModelFragment;
  };
  emit: (event: "update:visible", ...args: any[]) => void;
};

export const useUpdatePointing = ({ props, emit }: Props) => {
  const hasTomorrowPoiting = ref(false);
  const pointingFormRef = ref<any | undefined>(undefined);
  const { t } = useI18n();
  const toast = useToast();
  const { onDone, onError, mutate } = useUpdatePointingMutation();
  const previousTimes = toTimeData(props.pointing.previous?.times);

  const { validateFields, errors, form } = useValidation({
    times: {
      $value: toTimeData(props.pointing.times),
      $rules: [(m: Date[]) => !m?.length && !previousTimes.length && ""],
    },
    previous: {
      $value: previousTimes,
      $rules: [],
    },
  });

  onDone(({ data, errors }) => {
    if (data?.updatePointing) {
      apolloClient.refetchQueries({
        include: ["PointingByMonth"],
      });
      toast.add({
        severity: "success",
        summary: t("hr_pointing"),
        detail: t("hr_pointing_updated_successfully"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
      // on rafraichit les pointages
      emit("update:visible", false);
    } else {
      const isPointed = errors[0]?.message?.includes("unique_pointing");
      if (isPointed) {
        toast.add({
          severity: "error",
          summary: t("hr_pointing"),
          detail: t("hr_already_pointed"),
          life: parseInt(process.env.VUE_APP_TOAST_LIFE),
        });
      } else {
        toast.add({
          severity: "error",
          summary: t("hr_pointing"),
          detail: t("unknownError"),
          life: parseInt(process.env.VUE_APP_TOAST_LIFE),
        });
      }
    }
  });

  onError(() => {
    toast.add({
      severity: "error",
      summary: t("unknownError"),
      detail: t("unknownError"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });

  function submit(event: HTMLFormElement) {
    event.preventDefault();

    validateFields().then(({ times, previous }) => {
      // Si la dernière date de sortie est égale à la date du pointage
      const withFuturePointing = [...times]
        .reverse()
        .find(
          ({ exit }) =>
            exit &&
            !moment(exit as Date).isSame(
              moment(props.pointing.createdAt),
              "day"
            )
        )?.exit;
      const tomorrow = tomorrowPoiting(
        props.pointing.createdAt,
        times as TimeData[]
      );
      hasTomorrowPoiting.value = !!tomorrow;

      void mutate({
        input: {
          id: props.pointing.id,
          times: times,
          previousTimes: previous,
          withFuturePointing: !!withFuturePointing,
          tomorrowCreatedAt: tomorrow,
        },
      });
    });
  }

  const submitForm = () => pointingFormRef.value?.submit();

  return { errors, submit, form, pointingFormRef, submitForm };
};
