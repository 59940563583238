export default {
  hr_fistname: "Prénoms",
  hr_last_name: "Nom",
  cv: "CV",
  identity: "Pièce d'identité",
  function: "Fonction",
  sector: "Secteur",
  hr_choose_cv: "Choisir le CV",
  hr_choose_identity: "Choisir la pièce d'dentité",
  hr_departure_date: "Date de départ",
  hr_entry_date: "Date d'entrée",
  hr_pointing_entry_required: "L'heure d'entrée est obligatoire",
  hr_parent_sector: "Secteur parent",
  hr_contacts: "Contacts",
  hr_add_pointing: "Nouveau pointage",
  hr_edit_pointing: "Modifier le pointage",
  hr_remove_pointing: "Supprimer le pointage",
  hr_pointing: "Pointage",
  hr_pointing_added_successfully: "Pointage effectué avec succès!",
  hr_already_pointed: "L'employé a été déjà pointé !",
  hr_pointing_updated_successfully: "Pointage modifié avec succès!",
  hr_entry: "Entrée",
  hr_exit: "Sortie",
  hr_full_name: "{lastName} {firstName}",
  hr_pointing_removed_warning:
    "Etes-vous sûr de vouloir supprimer ce pointage ?",
  hr_pointing_removed_successfully: "Pointage supprimé avec succès!",
};
