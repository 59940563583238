import moment from "moment";
import { computed, ref } from "vue";
import { useEmployees } from "../../employee/hooks/useEmployees";
import {
  EmployeeModelFragment,
  PointingModelFragment,
  usePointingByMonthQuery,
} from "@/graphql/types";
import { activeActivity } from "@/plugins/i18n";
import { secureLocalStorage } from "@/graphql/utils/encrypt-storage";
import { CreatePointingType } from "./useCreatePointing";

export const usePoitings = () => {
  const dialogs = ref({
    create: false,
    update: false,
    employees: [] as EmployeeModelFragment[],
    pointing: undefined as PointingModelFragment | undefined,
    createdAt: "",
    type: "single" as CreatePointingType,
  });
  const savedView = "POINTING_VIEW_MODE";
  const viewOption = ref<string>(
    secureLocalStorage.getItem(savedView) || "icon"
  );

  const setPointingView = (view: string) => {
    viewOption.value = view;
    secureLocalStorage.setItem(savedView, view);
  };

  const viewOptions = [
    {
      label: "Icons",
      icon: "pi pi-bookmark-fill",
      command: () => setPointingView("icon"),
    },
    {
      label: "Libellés",
      icon: "pi pi-file-edit",
      command: () => setPointingView("label"),
    },
    {
      label: "Heures",
      icon: "pi pi-hourglass",
      command: () => setPointingView("hour"),
    },
  ];
  const monthOfYear = ref<Date>(new Date());

  const selection = ref<EmployeeModelFragment[]>([]);
  const { loading, employees, filter } = useEmployees();
  const { result, loading: pointingLoging } = usePointingByMonthQuery(() => ({
    input: {
      activityId: activeActivity.value.id,
      month: moment(monthOfYear.value as Date).format("YYYY-MM"),
    },
  }));
  const columns = computed(() => {
    const mMoment = moment(monthOfYear.value as Date);
    const month = mMoment.format("YYYY-MM");
    const daysInMonth = new Array(mMoment.daysInMonth())
      .fill(0)
      .map((_, index) => {
        const day = (index + 1).toString().padStart(2, "0");
        return {
          field: `${month}-${day}`,
          header: day,
        };
      });

    return [
      { field: "sector.name", header: "Secteur" },
      { field: "function.name", header: "Fonction" },
      { field: "lastName", header: "Nom" },
      { field: "firstName", header: "Prénom" },
    ].concat(daysInMonth);
  });

  const findPointage = (employeeId: number, month: string) => {
    return result.value?.pointingByMonth.find(
      (pointing) =>
        pointing.employeeId === employeeId && pointing.createdAt === month
    );
  };

  const handleCreate = (employee: EmployeeModelFragment, createdAt: string) => {
    dialogs.value.create = true;
    dialogs.value.employees = [employee];
    dialogs.value.createdAt = createdAt;
    dialogs.value.type = "single";
    selection.value = [];
  };

  const handleCreateAll = () => {
    const month = moment(monthOfYear.value as Date)
      .startOf("days")
      .format("YYYY-MM-DD");
    dialogs.value.createdAt = month;
    dialogs.value.employees = selection.value;
    dialogs.value.create = selection.value.length > 0;
    dialogs.value.type = "all";
  };

  const handleUpdate = (
    pointing: PointingModelFragment,
    employee: EmployeeModelFragment
  ) => {
    dialogs.value.update = true;
    dialogs.value.pointing = pointing;
    dialogs.value.employees = [employee];
    selection.value = [];
  };

  return {
    loading,
    employees,
    filter,
    viewOptions,
    viewOption,
    monthOfYear,
    columns,
    pointingLoging,
    selection,
    findPointage,
    dialogs,
    handleCreate,
    handleUpdate,
    handleCreateAll,
  };
};
